<template>
  <v-container fluid>
    <h1 class="titulo">Prêmio Pago na Central</h1>

    <v-card elevation="1">
      <v-card-text>
        <v-row no-gutters justify="end">
          <v-col>
            <v-row>
              <v-col>
                <v-text-field
                  :label="$gettext('Revenda')"
                  v-model="codRevenda"
                  outlined
                  dense
                />
              </v-col>

              <v-col>
                <v-text-field
                  :label="$gettext('Valor do premio')"
                  v-model="valuePrizes"
                  outlined
                  dense
                />
              </v-col>

              <v-col>
                <v-text-field
                  :label="$gettext('Nº Jogo / token')"
                  v-model="nrJogo"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col class="text-end">
            <v-btn
              style="margin-left: 16px;"
              :color="variables.colorPrimary"
              dark
            >
              Filtrar
            </v-btn>
          </v-col>


          <div style="margin-left: 16px;">
            <OrganizzeCalendar :dense="true" :getDates="syncDate"/>
          </div>

          <v-btn
            style="margin-left: 16px;"
            :loading="loading"
            :disabled="loading"
            :color="variables.colorPrimary"
            @click="enviar()"
            :dark="!loading">
            BUSCAR
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>

    <v-skeleton-loader
      type="table"
      style="margin-top: 16px;"
      :loading="loading"
      :transition-group="'fade-transition'">
      <v-card elevation="1">
        <v-list-group
          v-for="(prizes, key) in filteredPrizeListMap"
          :key="key"
          v-model="listStatus[key]"
          no-action
          sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-row no-gutters>
                <h2 style="font-weight: bold; text-transform: uppercase;">{{key}}</h2>
                <h4 style="font-weight: bold; margin-left: 8px; align-self: center; color: green">
                  - Total baixado:{{totalAmountDownloaded[key] | currency}}
                </h4>
              </v-row>
            </v-list-item-content>
          </template>

          <v-list-item sub-group>
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Revenda / Pagamento</th>
                      <th class="text-left">Nº Jogo / token</th>
                      <th class="text-left">Loteria</th>
                      <th class="text-left">valor do Jogo (R$)</th>
                      <th class="text-left">Valor do Prêmio (R$)</th>
                      <th class="text-left">Data do Sorteio</th>
                      <th class="text-left">Trans / Dt. Solicitação</th>
                      <th class="text-left">Atualizado</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(prize, index) in prizes" :key="index">
                      <td>{{ getEntityOrigin(prize) }} {{ getEntityPayament(prize) }}</td>
                      <td>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >{{ prize.winners ? prize.winners.games.gameNumber : prize.wpr.token }}</span>
                          </template>
                          <span>{{ prize.id }}</span>
                        </v-tooltip>
                       
                      </td>
                      <td>{{ prize.winners ? prize.winners.sweepstake.cod : '-' }}</td>
                      <td>{{ getGuessValue(prize) }}</td>
                      <td>{{ prize.value | currency }}</td>
                      <td>{{ getDataSorteio(prize) }}</td>
                      <td>{{ getDataCreated(prize) }}</td>
                      <td>{{prize.updated | datas}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-list-item>
        </v-list-group>
      </v-card>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
import { incRequestCounter, REQUEST_KEY_ALL } from '@/utils/miscellaneous'
import { sum, map, groupBy, filter, uniqBy } from 'lodash'
import moment from 'moment'
import { currency } from '@/core/service/utils'

const namespaceStore = 'premioPagoCentral'

export default {
  name: 'PremioPagoCentral',
  props: {},
  directives: {},
  components: {
    OrganizzeCalendar: () => import('@/views/components/organizze/calendar')
  },
  data: () => ({
    loading: false,
    entityType: 0,
    entityList: [],
    startEntity: {},
    endEntity: {},
    startDate: null,
    endDate: null,
    selectedLotery: {},
    loteryList: [],
    listStatus: {},
    totalAmountDownloaded: {},
    codRevenda: '',
    valuePrizes: '',
    nrJogo: '',
  }),
  created () {},
  mounted () {
    const self = this
    self.buscarEntidades()
    self.buscarLoterias()
    self.buscarUsuarios()
  },
  computed: {
    ...mapGetters(namespaceStore, ['supervisorList', 'promoterList', 'agencyList', 'prizeListMap', 'userList']),
    variables: () => variables,

    filteredPrizeListMap () {
      let tempPrizeListMap = this.prizeListMap
      const arrMap = []
      if (this.codRevenda !== '' && this.codRevenda) {
        tempPrizeListMap = Object.values(tempPrizeListMap).filter(it => {
          const filteredPrize = it.filter(prize => {
            return prize.entity.cod.toUpperCase().includes(this.codRevenda.toUpperCase())
          })
          arrMap.push(...filteredPrize)
        })

        const arrUniq = uniqBy(arrMap, 'id')
        tempPrizeListMap = this.formatObject(arrUniq)
      }
      
      if (this.valuePrizes !== '' && this.valuePrizes) {
        tempPrizeListMap = Object.values(tempPrizeListMap).filter(it => {
          const filteredPrize = it.filter(prize => {
            return prize.value.toUpperCase().includes(this.valuePrizes.toUpperCase())
          })
          arrMap.push(...filteredPrize)
        })
        
        const arrUniq = uniqBy(arrMap, 'id')
        tempPrizeListMap = this.formatObject(arrUniq)
      }

      if (this.nrJogo !== '' && this.nrJogo) {
        tempPrizeListMap = Object.values(tempPrizeListMap).filter(it => {
          const filteredPrize = it.filter(prize => {
            return prize.winners ? 
              String(prize.winners.games.gameNumber).toUpperCase().includes(this.nrJogo.toUpperCase()) :
              String(prize.wpr.token).toUpperCase().includes(this.nrJogo.toUpperCase())
          })
          arrMap.push(...filteredPrize)
        })
        
        const arrUniq = uniqBy(arrMap, 'id')
        tempPrizeListMap = this.formatObject(arrUniq)
      }
      console.log(uniqBy(tempPrizeListMap))
      return tempPrizeListMap
    }
  },
  watch: {
    'entityType' () {
      const self = this
      if (self.entityType === 2) self.entityList = self.supervisorList
      else if (self.entityType === 3) self.entityList = self.promoterList
      else if (self.entityType === 4) self.entityList = self.agencyList
    },

    'prizeListMap' (val) {
      const self = this

      for (const key in val) {
        if (Object.hasOwnProperty.call(val, key)) {
          self.listStatus[key] = true
          self.totalAmountDownloaded[key] = sum(map(val[key], item => parseFloat(item.value)))
        }
      }
    }
  },
  methods: {
    ...mapActions(namespaceStore, ['getEntities', 'submit', 'getUsers']),
    ...mapActions('sweepstakesRecurrent', { getSweepstakesRecurrent: 'getItens' }),

    buscarEntidades () {
      const self = this
      const data = {
        '_filter': '{"entityTypeId_In":"2,3,4"}',
        'pageSize': 999999
      }

      self.loading = incRequestCounter(REQUEST_KEY_ALL, 1)
      self.getEntities(data).then(() => {}).catch(e => { console.error(e) }).finally(() => {
        self.entityType = 4
        self.loading = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    syncDate (val) {
      const self = this
      self.startDate = val.startDate
      self.endDate = val.endDate
    },

    buscarLoterias () {
      const self = this

      self.loading = incRequestCounter(REQUEST_KEY_ALL, 1)
      self.getSweepstakesRecurrent({ order: { id: 'desc' } }).then((result) => {
        self.loteryList = result.data.resultMap.map(item => ({ text: `${item.id} - ${item.cod}`, value: item.id }))
        self.loteryList.unshift({ text: 'Todos', value: null })
        self.selectedLotery = self.loteryList[0]
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        self.loading = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    enviar () {
      const self = this
      const data = {
        page: 1,
        pageSize: 999999,
        dtInicial: self.startDate,
        dtFinal: self.endDate,
        loteria: self.selectedLotery.id,
        entity: self.startEntity.id,
        premiosWeb: false,
        baixados: true,
        prescrito: false,
        isCentralPrizePaid: true
      }

      self.loading = incRequestCounter(REQUEST_KEY_ALL, 1)
      self.submit(data).then(() => {}).catch(e => { console.error(e) }).finally(() => {
        self.loading = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    buscarUsuarios () {
      const self = this

      self.loading = incRequestCounter(REQUEST_KEY_ALL, 1)
      self.getUsers().then(() => {}).catch(e => { console.error(e) }).finally(() => {
        self.loading = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    getEntityOrigin (item) {
      if (item.winners && item.winners.games)
        return item.winners.games.entity.cod
      else 
        return item.entity.cod
    },

    getEntityPayament (item) {
      if (item.winners && item.winners.games){
        if (item.winners.games.entity.cod !== item.entity.cod)  {
          return `/ ${item.entity.cod}`
        }
      } else {
        return `/ ${item.wpr.entityPayer.cod}`
      }

      return 
    },
    getGuessValue (prize) {
      return prize.winners ? currency(prize.winners.totais.guessValue) : '-' 
    },

    getDataSorteio (prize) {
      return prize.winners ? moment(prize.winners.sweepstake.dtDraw).format('DD/MM/YYYY HH:mm')  : '-' 
    },

    getDataCreated (prize) {
      return prize.winners ? moment(prize.winners.games.created).format('DD/MM/YYYY HH:mm')  :  moment(prize.wpr.created).format('DD/MM/YYYY HH:mm')
    },

    formatObject (arrPrizes) {
      const result = {}
      const mappedPrizesPerUser = groupBy(arrPrizes, prize => prize.updatedUserId)

      for (const key in mappedPrizesPerUser) {
        if (Object.hasOwnProperty.call(mappedPrizesPerUser, key)) {
          const item = mappedPrizesPerUser[key]
          result[(filter(this.userList, user => user.id == key)[0] || {}).name || 'system'] = item
        }
      }

      return result
    }
  },
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  .radioGroupEntity::v-deep {
    .v-input--radio-group__input {
      justify-content: space-between;
    }
  }
</style>